/* eslint-disable no-param-reassign */
import {createAction, createReducer, createSelector} from '@reduxjs/toolkit';
import get from 'lodash/get';
import {toast} from '../../utils/toast';
import Api from '../../services/api';
import {getSelectedCompany} from '../settings';
import {parseFirstError} from '../../helpers/auth';
import {CUSTOM_EVENTS, triggerCustomEvent} from '../../helpers/customEvents';

export const toggleFetching = createAction('PROJECTS/TOGGLE_FETCHING');
export const setListFilters = createAction('PROJECTS/SET_LIST_FILTERS');
export const setProjectFilters = createAction('PROJECTS/SET_SINGLE_FILTERS');
export const setFullList = createAction('PROJECTS/SET_FULL_LIST');
export const renameProject = createAction('PROJECTS/RENAME_PROJECT');
export const setFullListForComparisons = createAction('PROJECTS/SET_FULL_LIST_FOR_COMPARISONS');
export const setList = createAction('PROJECTS/SET_LIST');
export const setProject = createAction('PROJECTS/SET_SINGLE');
export const setClearProject = createAction('PROJECTS/SET_CLEAR_SINGLE');
export const updateStudyShareStatus = createAction('PROJECTS/UPDATE_STUDY_SHARE_STATUS');
export const setSelectedStudies = createAction('PROJECTS/SET_SELECTED_STUDIES');
export const selectStudy = createAction('PROJECTS/SELECT_STUDY');

export const initialState = {
  list: {
    data: [],
    meta: {},
    filters: {
      page: 0,
      sortBy: 'created_at:desc',
      items_per_page: 26,
    },
    didSearch: false,
    isLoading: false,
  },
  fullList: {
    data: [],
    meta: {},
    filters: {
      page: 0,
      sortBy: 'created_at:desc',
      items_per_page: 99999,
    },
    didSearch: false,
    isLoading: false,
  },
  fullListForComparisons: {
    data: [],
    meta: {},
    filters: {
      page: 0,
      sortBy: 'created_at:desc',
      items_per_page: 99999,
    },
    didSearch: false,
    isLoading: false,
  },
  project: {
    data: [],
    studies: {
      data: [],
      meta: {},
      didSearch: false
    },
    selectedStudies: [],
    meta: {},
    filters: {
      page: 0,
      sortBy: 'created_at:desc',
      items_per_page: 26,
    },
    didSearch: false,
    isLoading: false,
  },
};

export default createReducer(initialState, {
  [selectStudy]: (state, action) => {
    if (!state.project.selectedStudies.includes(action.payload)) {
      state.project.selectedStudies = [...state.project.selectedStudies, action.payload];
    }
  },
  [setSelectedStudies]: (state, action) => {
    state.project.selectedStudies = action.payload.studies;
  },
  [setList]: (state, action) => {
    state.list.data = action.payload.data.projects;
    state.list.meta = action.payload.data.meta;
    state.list.isLoading = false;
    state.list.didSearch = true;
  },
  [setFullList]: (state, action) => {
    state.fullList.data = action.payload.data.projects;
    state.fullList.meta = action.payload.data.meta;
    state.fullList.isLoading = false;
    state.fullList.didSearch = true;
  },
  [renameProject]: (state, action) => {
    state.fullList.data = state.fullList.data.map(
      (project) => {
        if (project.id === action.payload.projectId) {
          return {...project, name: action.payload.name};
        }

        return project;
      }
    );
  },
  [setFullListForComparisons]: (state, action) => {
    state.fullListForComparisons.data = action.payload.data.projects;
    state.fullListForComparisons.meta = action.payload.data.meta;
    state.fullListForComparisons.isLoading = false;
    state.fullListForComparisons.didSearch = true;
  },
  [setListFilters]: (state, action) => {
    state.list.filters = action.payload.filters;
  },
  [setProject]: (state, action) => {
    state.project.data = action.payload.data;
    state.project.studies.data = action.payload.data.studies.data;
    state.project.studies.meta = action.payload.data.studies.meta;
    state.project.studies.didSearch = true;
    state.project.meta = action.payload.data.meta;
    state.project.isLoading = false;
    state.project.didSearch = true;
  },
  [setClearProject]: (state) => {
    state.project.data = initialState.project.data;
    state.project.studies.data = initialState.project.studies.data;
    state.project.studies.meta = initialState.project.studies.meta;
    state.project.studies.didSearch = initialState.project.studies.didSearch;
    state.project.meta = initialState.project.meta;
    state.project.isLoading = initialState.project.isLoading;
    state.project.didSearch = initialState.project.didSearch;
  },
  [updateStudyShareStatus]: (state, action) => {
    const studyData = state.project.studies.data.find(
      (study) => study.meta.study.id === action.payload.studyId
    );

    if (studyData) {
      studyData.meta.study.share = action.payload.isShared;
    }
  },
  [setProjectFilters]: (state, action) => {
    state.project.filters = action.payload.filters;
  },
  [toggleFetching]: (state, action) => {
    if (action.payload.value) {
      state[action.payload.key][action.payload.value] = action.payload.toggle;

      return;
    }

    state[action.payload.key].isLoading = action.payload.toggle;
  },
});

export const getList = ({filters}) => async (dispatch, getState) => {
  try {
    const company = getSelectedCompany(getState());

    if (!company) {
      return;
    }

    const {id: companyId} = company;

    dispatch(toggleFetching({key: 'list', toggle: true}));

    const filtersWithType = {
      ...filters,
      type: 'thumbnail'
    };

    const {data} = await Api.projects.getAll({companyId, filters: filtersWithType});

    dispatch(setList(data));
    dispatch(setListFilters({filters}));
  } catch (err) {
    if (err) {
      toast.error('Couldn\'t fetch your projects');
    }
  } finally {
    dispatch(toggleFetching({key: 'list', toggle: false}));
  }
};

export const getFullList = (filters) => async (dispatch, getState) => {
  try {
    const company = getSelectedCompany(getState());

    if (!company) {
      return;
    }

    const {id: companyId} = company;

    dispatch(toggleFetching({key: 'fullList', toggle: true}));

    const filtersObj = filters || initialState.fullList.filters;

    const filtersWithType = {
      ...filtersObj,
      type: 'list'
    };

    const {data} = await Api.projects.getAll({companyId, filters: filtersWithType});

    dispatch(setFullList(data));
  } catch (err) {
    if (err) {
      toast.error('Couldn\'t fetch your projects');
    }
  } finally {
    dispatch(toggleFetching({key: 'fullList', toggle: false}));
  }
};

export const getFullListForComparisons = () => async (dispatch, getState) => {
  try {
    const company = getSelectedCompany(getState());

    if (!company) {
      return;
    }

    const {id: companyId} = company;

    dispatch(toggleFetching({key: 'fullListForComparisons', toggle: true}));

    const filtersWithType = {
      ...initialState.fullListForComparisons.filters,
      type: 'list'
    };

    const {data} = await Api.projects.getAll({companyId, filters: filtersWithType});

    dispatch(setFullListForComparisons(data));
  } catch (err) {
    if (err) {
      toast.error('Couldn\'t fetch your projects');
    }
  } finally {
    dispatch(toggleFetching({key: 'fullListForComparisons', toggle: false}));
  }
};

export const getById = ({filters}, projectId) => async (dispatch, getState) => {
  try {
    const company = getSelectedCompany(getState());

    if (!company) {
      return;
    }

    const {id: companyId} = company;

    dispatch(toggleFetching({key: 'project', toggle: true}));

    const {data} = await Api.projects.getById({companyId, projectId, filters});

    dispatch(setProject(data));
    dispatch(setProjectFilters({filters}));
  } catch (err) {
    if (err) {
      toast.error('Couldn\'t fetch your project');
    }
  } finally {
    dispatch(toggleFetching({key: 'project', toggle: false}));
  }
};

export const createProject = (name) => async (dispatch, getState) => {
  try {
    const company = getSelectedCompany(getState());

    if (!company) {
      return;
    }

    const {id: companyId} = company;

    dispatch(toggleFetching({key: 'list', toggle: true}));

    const {data} = await Api.projects.create({companyId, name});

    if (data && data.success === false) {
      throw new Error({response: {data}});
    } else {
      triggerCustomEvent(CUSTOM_EVENTS.CREATED_PROJECT);
      toast.success('Project created');

      dispatch(getList({filters: initialState.list.filters}));
      dispatch(getFullList());
    }
  } catch (err) {
    const error = parseFirstError(err, 'Couldn\'t create new project.');

    toast.error(error);
  } finally {
    dispatch(toggleFetching({key: 'list', toggle: false}));
  }
};

export const deleteById = (projectId) => async (dispatch, getState) => {
  try {
    const company = getSelectedCompany(getState());

    if (!company) {
      return;
    }

    const {id: companyId} = company;

    await Api.projects.deleteById(projectId, companyId);

    dispatch(getList({filters: initialState.list.filters}));
    dispatch(getFullList());
  } catch (err) {
    const error = parseFirstError(err, `Couldn't delete project #${projectId}.`);

    toast.error(error);
  }
};

export const attachAnalysisToProject = (projectId, studyId) => async (dispatch, getState) => {
  try {
    const company = getSelectedCompany(getState());

    if (!company) {
      return;
    }

    const {id: companyId} = company;

    await Api.projects.attachStudy(projectId, companyId, studyId);
  } catch (err) {
    const error = parseFirstError(err, `Couldn't add analysis #${studyId} to study #${projectId}.`);

    toast.error(error);
  }
};

export const attachAnalysesToProject = (projectId, ids) => async (dispatch, getState) => {
  try {
    const company = getSelectedCompany(getState());

    if (!company) {
      return;
    }

    const {id: companyId} = company;

    await Api.projects.moveStudies(projectId, companyId, ids);
    toast.success('Analyses added successfully');
  } catch (err) {
    const error = parseFirstError(err, `Couldn't add analysis #${ids} to study #${projectId}.`);

    toast.error(error);
  }
};

export const detachAnalysisFromProject = (projectId, studyId) => async (dispatch, getState) => {
  try {
    const company = getSelectedCompany(getState());

    if (!company) {
      return;
    }

    const {id: companyId} = company;

    await Api.projects.detachStudy(projectId, companyId, studyId);
  } catch (err) {
    const error =
      parseFirstError(err, `Couldn't remove analysis #${studyId} from study #${projectId}.`);

    toast.error(error);
  }
};

export const detachAnalysesFromProject = (projectId, ids) => async (dispatch, getState) => {
  try {
    const company = getSelectedCompany(getState());

    if (!company) {
      return;
    }

    const {id: companyId} = company;

    await Api.projects.removeStudies(projectId, companyId, ids);
  } catch (err) {
    const error =
      parseFirstError(err, `Couldn't remove analysis #${ids} from study #${projectId}.`);

    toast.error(error);
  }
};

export const shareProjectWithUser = (projectId, userId) => async (dispatch, getState) => {
  try {
    const company = getSelectedCompany(getState());

    if (!company) {
      return;
    }

    const {id: companyId} = company;

    await Api.projects.shareWithUser(projectId, companyId, userId);
  } catch (err) {
    const error = parseFirstError(err, 'Couldn\'t change share to user');

    toast.error(error);
  }
};

export const unShareProjectWithUser = (projectId, userId) => async (dispatch, getState) => {
  try {
    const company = getSelectedCompany(getState());

    if (!company) {
      return;
    }

    const {id: companyId} = company;

    await Api.projects.unShareWithUser(projectId, companyId, userId);
  } catch (err) {
    const error = parseFirstError(err, 'Couldn\'t change share to user');

    toast.error(error);
  }
};

export const getProjectSharedInfo = (projectId) => async (dispatch, getState) => {
  try {
    const company = getSelectedCompany(getState());

    if (!company) {
      return;
    }
    const {id: companyId} = company;

    return await Api.projects.sharedInfo(projectId, companyId);
  } catch (err) {
    const error = parseFirstError(err, 'Couldn\'t get share info');

    toast.error(error);
  }
};

export const clearProject = () => async (dispatch) => {
  dispatch(setClearProject());
};

export const _selectProjectsStore = (state) => state?.projects;

export const selectCurrentProjectInfo = createSelector(_selectProjectsStore, (projects) => {
  const id = get(projects, 'project.data.id', null);

  const name = get(projects, 'project.data.name', null);

  return {id, name};
});
