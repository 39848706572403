import axios from '../../helpers/axios';

export default {
  getAll: ({companyId, filters: params}) => axios.get(`/api/projects/${companyId}`, {params}),
  getById: ({companyId, projectId, filters: params}) => axios.get(
    `/api/projects/${companyId}/${projectId}/`, {params}
  ),
  create: ({companyId, name}) => axios.post(`/api/projects/${companyId}`, {
    name,
  }),
  update: ({companyId, projectId, name}) => axios.put(
    `api/projects/${companyId}/${projectId}`, {name}
  ),
  deleteById: (projectId, companyId) => axios.delete(
    `/api/projects/${companyId}/${projectId}/`
  ),
  attachStudy: (projectId, companyId, studyId) => axios.post(
    `/api/projects/${companyId}/${projectId}/attach/${studyId}`
  ),
  moveStudies: (projectId, companyId, ids) => axios.post(
    `/api/projects/${companyId}/${projectId}/move`, {studies: ids}
  ),
  detachStudy: (projectId, companyId, studyId) => axios.post(
    `/api/projects/${companyId}/${projectId}/detach/${studyId}`
  ),
  removeStudies: (projectId, companyId, ids) => axios.post(
    `/api/projects/${companyId}/${projectId}/remove`, {studies: ids}
  ),
  shareWithUser: (projectId, companyId, userId) => axios.post(
    `/api/projects/${companyId}/${projectId}/share/${userId}`
  ),
  unShareWithUser: (projectId, companyId, userId) => axios.delete(
    `/api/projects/${companyId}/${projectId}/share/${userId}`
  ),
  sharedInfo: (projectId, companyId) => axios.get(
    `/api/projects/${companyId}/${projectId}/shared`
  ),
};
