import axios from '../../helpers/axios';

export default {
  getList: ({companyId, filters: params}) => axios.get(`/api/studies/${companyId}/list`, {params}),
  getVideoList: ({companyId, filters: params}) => axios.get(`/api/studies/video/${companyId}/list`, {params}),
  getSearch: ({companyId, filters: params}) => axios.get(`/api/search/${companyId}`, {params}),
  getById: (studyId) => axios.get(`/api/studies/${studyId}`),
  getVideoById: (studyId) => axios.get(`/api/studies/video/${studyId}`),
  getSharedById: (studyId) => axios.get(`/api/studies/${studyId}/show-shared`),
  // endpoint not used anymore, was made for old analysis
  generateFocusMap:
      (studyId) => axios.post(`/api/studies/${studyId}/on-demand`, {type: 'focusmap'}),
  deleteById: (studyId) => axios.delete(`/api/studies/${studyId}`),
  deleteStudies: (companyId, studyIds) => axios.delete(`/api/studies/${companyId}`, {data: {studies: studyIds}}),
  create: ({companyId, data}) => axios.post(`/api/studies/${companyId}`, data),
  createVideo: ({companyId, data}) => axios.post(`/api/studies/video/${companyId}`, data),
  edit: (id, data) => axios.put(`/api/studies/${id}/study-info`, data),
  getEditData: ({id, aoi}) => axios.get(`/api/studies/${id}/edit`, {params: {aoi}}),
  uploadImage: ({id, formData}) => axios.post(`/api/studies/${id}/upload-image`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }),
  analyseFold: (id, data) => axios.post(`/api/studies/fold/${id}/analyze`, data),
  uploadVideo: ({id, formData}) => axios.post(`/api/studies/video/${id}/upload`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }),
  generateThumbnailVideo: ({id}) => axios.get(`/api/studies/video/${id}/thumbnail`, {
    headers: {
      accept: 'application/json',
    },
  }),
  removeImage: ({id}) => axios.delete(`/api/studies/image/${id}`),
  // old
  addAoi: ({resourceId, aoi}) => axios.post(
    `/api/studies/${resourceId}/aoi`, {aoiCoordinates: aoi}
  ),
  calculateAoi: ({resourceId, aoi}) => axios.post(
    `/api/studies/${resourceId}/aoi/calculate`, {aoiCoordinates: aoi}
  ),
  removeAoi: ({aoiId}) => axios.delete(`/api/studies/${aoiId}/aoi`),
  updateAoi: ({aoiId, name}) => axios.patch(`/api/studies/${aoiId}/aoi`, {name}),
  copyAoisTo: ({resourceId, studies}) => axios.post(`/api/studies/${resourceId}/aoi/copy`, {studies}),
  removeAois: ({resourceId}) => axios.delete(`/api/studies/${resourceId}/all-aoi`),
  removeAutoAois: ({resourceId}) => axios.delete(`/api/studies/${resourceId}/auto-aois`),
  startStudy: ({id, crop}) => axios.put(`/api/studies/${id}`, {crop}),
  analyseStudies: ({companyId, studies}) => axios.post(`/api/studies/${companyId}/analize`, {studies}),
  analyseVideoStudies: ({companyId, studies}) => axios.post(`/api/studies/video/${companyId}/analize`, {studies}),
  downloadStudy: ({id, deviceId}) => axios.get(`/api/studies/${id}/download`, {params: {deviceId}}),
  downloadResource: (url) => axios.get(url),
  downloadResourceBlob: (url) => axios.get(url, {responseType: 'blob'}),
  getResourceDetails: ({id}) => axios.get(`/api/studies/resource/${id}`),
  addUrlToStudy: ({id, url, fullPage}) => axios.post(`/api/studies/${id}/save-url`, {url, fullPage}),
  setSharedValue: ({id, shared}) => axios.patch(`/api/studies/${id}/share`, {share: shared}),
  sharedInfo: (id) => axios.get(
    `/api/studies/${id}/shared`
  ),
  shareWithUser: (studyId, userId) => axios.post(
    `/api/studies/${studyId}/share/${userId}`
  ),
  unShareWithUser: (studyId, userId) => axios.delete(
    `/api/studies/${studyId}/share/${userId}`
  ),
};
